import type { UserSettingsUpdateRequest } from '@lib/requests/user';
import type { UserWithSettingsResponse } from '@lib/responses/user';
import type { API } from './api';

export class UserService {
  constructor(private api: API) {}

  getUser = () => {
    return this.api.get<UserWithSettingsResponse>('/user');
  };

  saveUserProfile = (data: UserSettingsUpdateRequest) => {
    return this.api.put<UserWithSettingsResponse>('/user', data);
  };
}
