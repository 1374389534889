import { configuratorMaterialSchema } from '@lib/validation';
import type {
  ConfiguratorDynamicFieldDefinition,
  ConfiguratorFieldState,
  ConfiguratorMaterialFieldType,
} from '@ui/features/configurator/types';
import { configuratorService } from '@ui/services';

export type ConfiguratorMaterialOptionMeta = {
  partId: string;
  description?: string | null;
  stockUm: string; // @TODO(shawk): enum?
  unitMaterialCost: number;
};

export type ConfiguratorMaterialParams = {
  productCode: string;
};

export const materialFieldDefinition: ConfiguratorDynamicFieldDefinition<
  ConfiguratorMaterialOptionMeta,
  ConfiguratorMaterialParams
> = {
  endpoints: {
    list: '/configurator/materials',
  },

  mapOption: (data) => {
    const material = configuratorMaterialSchema.parse(data);

    return {
      label: material.id,
      value: material.id,
      description: material?.description,
      meta: {
        partId: material.id,
        description: material.description,
        stockUm: material.stock_um,
        unitMaterialCost: material.unit_material_cost,
      },
    };
  },

  mapOptions: (materials) => {
    return materials.map(materialFieldDefinition.mapOption);
  },

  fetchOne: () => {
    throw new Error('Not implemented');
  },

  search: ({ siteCode, query, pagination, params }) => {
    return configuratorService.getFieldOptions({
      siteCode,
      fieldDefinition: materialFieldDefinition,
      query,
      pagination,
      params: {
        ...params,
        productCode: 'ZRAW',
      },
    });
  },
};

export function getMaterialField(
  fields: ConfiguratorFieldState,
  fieldKey: string,
): ConfiguratorMaterialFieldType {
  const field = fields[fieldKey];

  if (field.inputType !== 'material') {
    throw new Error(`Invalid operation type field with key ${fieldKey}`);
  }

  return field as ConfiguratorMaterialFieldType;
}

export const washerMaterialFieldDefinition = {
  ...materialFieldDefinition,
  search: ({ siteCode, query, pagination, params }) => {
    return configuratorService.getFieldOptions({
      siteCode,
      fieldDefinition: materialFieldDefinition,
      query,
      pagination,
      params: { ...params, productCode: 'WASHER' },
    });
  },
} satisfies ConfiguratorDynamicFieldDefinition<
  ConfiguratorMaterialOptionMeta,
  ConfiguratorMaterialParams
>;
