import type { SiteWithConfigResponse, SitesResponse } from '@lib/responses';
import type { API } from './api';

export class SiteService {
  constructor(private api: API) {}

  getAll = () => {
    return this.api.get<SitesResponse>('/sites');
  };

  getOne = (siteCode: string) => {
    return this.api.get<SiteWithConfigResponse>(
      `/sites/${siteCode.toLowerCase()}`,
    );
  };
}
