import { Card, Center, Link, Text } from '@chakra-ui/react';
import { Router } from '@ui/config/routes';
import type { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';

export const NotFound: FC = () => {
  return (
    <Center height="100vh">
      <Card p={10} textAlign="center" justifyContent="center">
        <Text fontSize={36} mb={4}>
          404
        </Text>
        <Text mb={8}>Couldn't find what you're looking for</Text>
        <Link
          as={RouterLink}
          to={Router.path(Router.routes.root)}
          fontWeight="bold"
        >
          Back to Home
        </Link>
      </Card>
    </Center>
  );
};

export default NotFound;
