import { useQuery, keepPreviousData } from '@tanstack/react-query';
import { siteService } from '@ui/services';

export const siteQuery = (siteCode: string) => {
  return {
    queryKey: ['site', siteCode],
    queryFn: () => siteService.getOne(siteCode),
  };
};

export function useGetSite(siteCode: string) {
  return useQuery({
    ...siteQuery(siteCode.toLowerCase()),
    placeholderData: keepPreviousData,
  });
}
