import { isError } from '@sindresorhus/is';
import { useQueryClient } from '@tanstack/react-query';
import {
  type ConfiguratorOperationTypeOptionMeta,
  buildOperationTypeFieldUpdates,
} from '@ui/features/configurator/data/field-definitions/operation_type';
import { resourceFieldDefinition } from '@ui/features/configurator/data/field-definitions/resource';
import { useConfiguratorStore } from '@ui/features/configurator/hooks';
import { setFieldError, updateField } from '@ui/features/configurator/store';
import type { ConfiguratorOperationTypeFieldType } from '@ui/features/configurator/types';
import { useCurrentSite } from '@ui/hooks/useCurrentSite';
import { memo, useCallback, useState } from 'react';
import { getUntrackedObject } from 'react-tracked';
import {
  ConfiguratorBaseSingleChoiceInput,
  type SelectedItem,
} from './BaseSingleChoiceInput';
import type { ConfiguratorInputProps } from './types';

function OperationTypeInputComponent({
  field,
  ...widths
}: ConfiguratorInputProps<ConfiguratorOperationTypeFieldType>) {
  const currentSite = useCurrentSite();
  const queryClient = useQueryClient();
  const [isPending, setIsPending] = useState(false);
  const { fields: trackedFields, dispatch } = useConfiguratorStore();

  /**
   * Using the tracked fields causes an issue with reusable operations:
   * proxy must report the same value for the non-writable, non-configurable property
   *
   * This is probably a sign to dump react-tracked, but I will do that in another PR
   * TODO: configurator - MW-701 - Remove react tracked
   */
  const fields = getUntrackedObject(trackedFields);

  if (!fields) {
    throw new Error('Tracked object not found');
  }

  if (field.inputType !== 'operation_type') {
    throw new Error(`Invalid input type "${field.inputType}"`);
  }

  if (!field.inputSource) {
    throw new Error(
      `operation inputs must have an associated input_source, but "${field.fieldKey}" had none`,
    );
  }

  const handleSelectedItemChange = useCallback(
    async (item: SelectedItem<ConfiguratorOperationTypeOptionMeta> | null) => {
      // update the Operation Type field immediately so user sees their selection
      dispatch(updateField(field.fieldKey, item));

      let resourceOption = null;

      /**
       * @NOTE(shawk): This is not expected to happen. We query for Operation
       * Types where `resource_id` is NOT NULL. But just in case...
       */
      if (item && !item.meta?.resourceId) {
        dispatch(
          setFieldError(
            field.fieldKey,
            'This Operation Type does not have a Resource',
          ),
        );

        return;
      }

      /**
       * If the Operation Type references a resource, fetch it, mapping the
       * resource to a select option based on the field definition for resources.
       */
      if (item?.meta?.resourceId) {
        setIsPending(true);

        const id = item.meta.resourceId;

        try {
          resourceOption = await queryClient.fetchQuery({
            queryKey: ['configurator', 'dataSource', 'resource', id],
            queryFn: () =>
              resourceFieldDefinition.fetchOne(currentSite.code, id),
          });
        } catch (e) {
          console.error(
            isError(e)
              ? e.message
              : `Failed to fetch resource option for resource with id: "${id}"`,
          );

          setIsPending(false);

          dispatch(
            setFieldError(
              field.fieldKey,
              'The Operation Type did not have a valid Resource',
            ),
          );

          // reset operation type and return
          return dispatch(updateField(field.fieldKey, null));
        }
      }

      // update all of the dependent fields
      dispatch(
        buildOperationTypeFieldUpdates(fields, field, item, resourceOption),
      );

      setIsPending(false);
    },
    [queryClient, dispatch, fields, field, currentSite.code],
  );

  return (
    <ConfiguratorBaseSingleChoiceInput
      field={field}
      onItemSelected={handleSelectedItemChange}
      isPending={isPending}
      {...widths}
    />
  );
}

/**
 * Searchable select for Configurator forms.
 *
 * Requires ConfiguratorStoreProvider in a parent component.
 */
export const ConfiguratorOperationTypeInput = memo(OperationTypeInputComponent);
