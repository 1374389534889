import {
  Box,
  Flex,
  Menu,
  MenuButton,
  MenuGroup,
  MenuItem,
  MenuList,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { faBuilding, faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import theme from '@ui/config/theme';
import { CurrentSite } from '@ui/hooks/useCurrentSite';
import { useCurrentUser } from '@ui/hooks/useCurrentUser';
import { scrollbarCSS } from '@ui/util/css';
import { menuProps } from '@ui/util/menuProps';
import { useNavigationContext } from './context';
import { Router } from '@ui/config/routes';

type SiteSelectorProps = {
  currentSite?: CurrentSite;
};

export function SiteSelector({ currentSite }: SiteSelectorProps) {
  const { isOpen } = useNavigationContext();
  const currentUser = useCurrentUser();

  if (currentUser.sites.length === 0) {
    return null;
  }

  const sites = currentUser.sites;

  return (
    <Menu>
      <MenuButton
        width="100%"
        height="50px"
        opacity={0.72}
        fontSize={16}
        borderWidth="1px"
        borderColor="rgba(255, 255, 255, 0.4)"
        borderRadius={8}
        _hover={{
          opacity: 1,
          borderColor: 'rgba(255, 255, 255, 0.7)',
        }}
      >
        <Flex
          alignItems="center"
          justifyContent={isOpen ? 'flex-start' : 'center'}
        >
          <Tooltip label="Sites" placement="right" isDisabled={false}>
            <FontAwesomeIcon
              style={{ margin: 0 }}
              icon={faBuilding}
              width={36}
            />
          </Tooltip>

          {isOpen && (
            <Text
              fontWeight="semibold"
              fontFamily="navigationItem"
              opacity={isOpen ? 1 : 0}
              maxWidth={isOpen ? 'unset' : 0}
              maxHeight={isOpen ? 'unset' : 0}
              marginLeft={isOpen ? 3 : 0}
            >
              {currentSite ? currentSite.code : 'Select site...'}
            </Text>
          )}
        </Flex>
      </MenuButton>
      <MenuList
        borderRadius={0}
        color="mw.lightGrey"
        backgroundColor="mw.darkGrey"
        p="1.5rem"
        border="none"
      >
        <MenuGroup title="Current Site">
          <Box
            maxHeight="225px"
            overflow="auto"
            css={scrollbarCSS({
              background: theme.colors.mw.darkGrey,
              thumb: theme.colors.mw.lightGrey,
            })}
          >
            {sites.map((site) => {
              return (
                <MenuItem
                  key={site.id}
                  value={site.id}
                  {...menuProps.item}
                  p={0}
                >
                  <Flex
                    as={Link}
                    to={Router.sitePath(site, Router.routes.quoteQueue)}
                    p="12px"
                    _hover={{ textDecoration: 'none' }}
                  >
                    <Box width="24px">
                      {site.code === currentSite?.code && (
                        <FontAwesomeIcon icon={faCheck} />
                      )}
                    </Box>

                    <Box width="45px" fontWeight="bold" marginLeft={2}>
                      {site.code}
                    </Box>

                    <Box
                      width="225px"
                      whiteSpace="nowrap"
                      textOverflow="ellipsis"
                      overflow="hidden"
                    >
                      {site.name}
                    </Box>
                  </Flex>
                </MenuItem>
              );
            })}
          </Box>
        </MenuGroup>
      </MenuList>
    </Menu>
  );
}
