import type { SalesRepParams } from '@lib/requests/sales-rep';
import type { SalesRepWithEmployeeResponse } from '@lib/responses/sales-rep';
import type { API } from './api';

export class SalesRepService {
  constructor(private api: API) {}

  getSalesRepWithEmployee = (siteCode: string, params: SalesRepParams) => {
    const path = `/sales-rep/${params.salesRepId}`;

    return this.api.get<SalesRepWithEmployeeResponse>(
      this.api.sitePath(siteCode, path),
    );
  };
}
