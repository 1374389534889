import type { CustomerSearchQuery } from '@lib/requests/customer';
import type {
  CustomerContactResponse,
  CustomerManyResponse,
} from '@lib/responses/customer';
import type { CustomerTiers } from '@prisma-types';
import type { API } from './api';

export class CustomerService {
  constructor(private api: API) {}

  searchCustomers = (query: CustomerSearchQuery) => {
    return this.api.get<CustomerManyResponse>('/customer', query);
  };

  getCustomerTiers = () => {
    return this.api.get<CustomerTiers>('/customer/tiers');
  };

  getCustomerContacts = (customerId: string) => {
    return this.api.get<CustomerContactResponse>(
      `/customer/${customerId}/contacts`,
    );
  };
}
