import type { ModalProps } from '@ui/components/layout/Modal';
import { useMemo, useState } from 'react';
import { GlobalStateContext } from './context';

export function GlobalStateProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [activeModal, setActiveModal] = useState<ModalProps | null>(null);

  const value = useMemo(
    () => ({
      activeModal,
      setActiveModal,
    }),
    [activeModal],
  );

  return (
    <GlobalStateContext.Provider value={value}>
      {children}
    </GlobalStateContext.Provider>
  );
}
