import { Box, Button } from '@chakra-ui/react';
import { Alert } from '@ui/components/Alert';
import { isAxiosError } from 'axios';

export type QueryErrorProps = {
  title?: string;
  error?: Error;
  onRetry?: () => void;
  isRetrying?: boolean;
};

export function QueryError({
  title,
  error,
  onRetry,
  isRetrying = false,
}: QueryErrorProps) {
  let description = null;

  if (error) {
    if (isAxiosError(error)) {
      description = error.response?.data.message;
    } else {
      description = 'Something went wrong...';
    }
  }

  return (
    <Box padding={6} width="100%">
      <Alert
        status="error"
        title={title || 'Error loading!'}
        description={description}
      >
        {onRetry && (
          <Button
            variant="ghost"
            onClick={onRetry}
            ml="auto"
            isLoading={isRetrying}
            loadingText="Retrying..."
          >
            Try again
          </Button>
        )}
      </Alert>
    </Box>
  );
}
