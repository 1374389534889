import { Flash, FlashStatus } from './types';

export function flashMessage(
  status: FlashStatus,
  title: string,
  description?: string,
): Flash {
  return { [status]: { title, description } };
}

export function successFlash(title: string, description?: string) {
  return flashMessage('success', title, description);
}

export function infoFlash(title: string, description?: string) {
  return flashMessage('info', title, description);
}

export function warningFlash(title: string, description?: string) {
  return flashMessage('warning', title, description);
}

export function errorFlash(title: string, description?: string) {
  return flashMessage('error', title, description);
}
