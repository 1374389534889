import type { RouteItem, SiteLike } from './types';
import { generatePath } from 'react-router-dom';

export function path(
  route: RouteItem | string,
  params?: Record<string, string | number>,
): string {
  let path = '';

  if (typeof route === 'object') {
    path = route.path;
  } else {
    path = route;
  }

  return generatePath(path, params);
}

export function sitePath(
  site: SiteLike,
  route: RouteItem | string,
  params: Record<string, string | number> = {},
): string {
  return path(route, { ...params, siteCode: site.code.toLowerCase() });
}
