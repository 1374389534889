export type RequirementCalculationParams = {
  /** The quantity of material needed per 1 of the operation's input quantity.
   * In Visual, this corresponds to the QTY_PER field in the REQUIREMENT table.*/
  qtyPer: number;

  /** A constant quantity of material that is always used as part of this requirement,
   * in addition to the "quantity per" value.
   * In Visual, this corresponds to the FIXED_QTY field in the REQUIREMENT table.
   */
  fixedQty: number;

  /** The cost of material per quantity.
   * In Visual, this corresponds to the UNIT_MATERIAL_COST field in the REQUIREMENT table.
   */
  unitMaterialCost: number;

  // These fields may be necessary one day.
  // qtyPerType: string,
  // scrapPercent: number,
  // usageUM: string,
  // unitBurdenCost: number,
  // unitServiceCost: number,
};

export type RequirementCalculationResults = {
  /** The quantity of material required for the operation. */
  quantity: number;

  /** The total material cost for this requirement. */
  materialCost: number;
};

export function calculateRequirementCosts(
  params: RequirementCalculationParams,
  operationStartQuantity: number,
): RequirementCalculationResults {
  /** The input quantity of material for the operation associated with this requirement.
   * This quantity is calculated from the output quantity of
   * the operation and the operation's scrap values.
   * In Visual, this corresponds to the CALC_START_QTY field in the OPERATION table.*/
  const quantity = params.fixedQty + params.qtyPer * operationStartQuantity;
  const materialCost = quantity * params.unitMaterialCost;

  return { quantity, materialCost };
}
