import type {
  ConfiguratorValueType,
  WritableConfiguratorStoreState,
} from '@ui/features/configurator/types';
import { validateField } from '../helpers/validate-field';
import type { ConfiguratorAction } from './types';

export type UpdateFieldAction = ConfiguratorAction<
  'updateField',
  { fieldKey: string; value: ConfiguratorValueType }
>;

export const updateField = (
  fieldKey: string,
  value: ConfiguratorValueType,
): UpdateFieldAction => ({
  type: 'updateField',
  payload: {
    fieldKey,
    value,
  },
});

/**
 * Updates a step's value, runs validations, and updates the store metadata as needed.
 */
export function updateFieldAction(
  state: WritableConfiguratorStoreState,
  fieldKey: string,
  value: ConfiguratorValueType,
) {
  try {
    state.fields[fieldKey].value = value;
    state.fields[fieldKey].isTouched = true;

    validateField(state.fields, fieldKey);
  } catch (error) {
    console.error('caught updateFieldAction error', fieldKey, value, error);
  }
}
