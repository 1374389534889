import { isAxiosError } from 'axios';
import React from 'react';
import { useParams } from 'react-router-dom';
import { useGetSite } from '@ui/data/site';
import { CurrentSite, CurrentSiteContext } from '@ui/hooks/useCurrentSite';
import { CurrentUser, useCurrentUser } from '@ui/hooks/useCurrentUser';
import { FullScreenLoader } from '@ui/components/FullScreenLoader';
import { Navigate } from 'react-router-dom';
import { Router } from '@ui/config/routes';
import { errorFlash } from '@ui/hooks/useFlash';
import { NotFound } from '@ui/pages/NotFound';

type WithCurrentSiteProps = {
  checkAccess?: boolean;
  children: React.ReactNode;
};

export function WithCurrentSite({
  checkAccess = true,
  children,
}: WithCurrentSiteProps) {
  const currentUser = useCurrentUser();
  const params = useParams<{ siteCode: string }>();
  const currentSiteCode = params.siteCode;

  if (!currentSiteCode) {
    throw new Error('Missing site code!');
  }

  const currentSiteQuery = useGetSite(currentSiteCode);

  if (currentSiteQuery.isLoading && !currentSiteQuery.data) {
    return <FullScreenLoader loadingText="Loading site..." />;
  }

  if (currentSiteQuery.isError) {
    const error = currentSiteQuery.error;

    if (isAxiosError(error) && error?.response?.status === 404) {
      return <NotFound />;
    }

    throw new Error('Error fetching current site!');
  }

  if (currentSiteQuery.isSuccess) {
    const site = currentSiteQuery.data;

    if (checkAccess && !canAccessSite(currentUser, site)) {
      const to = Router.path(Router.routes.root);
      const flash = errorFlash(
        `You don't have access to ${site.code.toUpperCase()}`,
      );

      return <Navigate to={to} state={{ flash }} />;
    }

    return (
      <CurrentSiteContext.Provider value={site}>
        {children}
      </CurrentSiteContext.Provider>
    );
  }

  return null;
}

function canAccessSite(user: CurrentUser, site: CurrentSite) {
  return user.sites.some((s) => s.code === site.code);
}
