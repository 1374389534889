import { Flex } from '@chakra-ui/react';
import { FullScreenLoader } from '@ui/components/FullScreenLoader';
import { Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import { Navigation } from '@ui/components/layout/Navigation';
import { Router } from '@ui/config/routes';
import { faScrewdriverWrench } from '@fortawesome/free-solid-svg-icons';
import { WithPermission } from '@ui/components/Permission';
import { ModalLayout } from '@ui/components/layout/ModalLayout';

export function HomeLayout() {
  return (
    <>
      <ModalLayout />

      <Flex direction="row">
        <Navigation.Menu>
          <Navigation.SiteSelector />

          <WithPermission permissions={Router.routes.admin.permissions}>
            <Navigation.Item
              label="Admin"
              path={Router.path(Router.routes.admin)}
              icon={faScrewdriverWrench}
            />
          </WithPermission>
        </Navigation.Menu>

        {/* @NOTE(shawk): ensure <NavigationBar /> stays visible
            while descendent lazy-loaded routes are loading */}
        <Suspense fallback={<FullScreenLoader />}>
          <Outlet />
        </Suspense>
      </Flex>
    </>
  );
}
