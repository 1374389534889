import { path, sitePath } from './path-helpers';
import { routesConfig } from './config';

export { AppRoutes } from './AppRoutes';
export * from './types';

export const Router = {
  routes: routesConfig,
  path,
  sitePath,
};
