import { Navigate, useParams } from 'react-router-dom';
import { FullScreenLoader } from '@ui/components/FullScreenLoader';
import { Router } from '@ui/config/routes';
import { useGetQuote } from '@ui/data/quote/get-quote';
import { UnreachableCaseError } from '@lib/validation';
import { errorFlash } from '@ui/hooks/useFlash';

export type Action = 'index' | 'new' | 'edit' | 'view' | 'feedback';
export type ActionWithQuote = Exclude<Action, 'index' | 'new'>;

export function QuoteRedirector({ action }: { action: Action }) {
  if (action === 'index' || action === 'new') {
    return <Navigate to={Router.path(Router.routes.root)} />;
  }

  return <QuoteRedirectorWithQuote action={action} />;
}

function QuoteRedirectorWithQuote({ action }: { action: ActionWithQuote }) {
  const { id } = useParams<{ id: string }>();

  if (!id) {
    throw new Error('Expected URL path to have :id');
  }

  const quoteQuery = useGetQuote(id);

  if (quoteQuery.isLoading) {
    return <FullScreenLoader />;
  }

  if (quoteQuery.isError) {
    const flash = errorFlash(`Unable to find quote with ID '${id}'`);

    return <Navigate to={Router.path(Router.routes.root)} state={{ flash }} />;
  }

  if (quoteQuery.isSuccess) {
    const quote = quoteQuery.data;

    return <Navigate to={quoteUrl(quote.siteId, quote.id, action)} />;
  }

  return null;
}

function quoteUrl(code: string, id: string, action: ActionWithQuote) {
  switch (action) {
    case 'edit':
      return Router.sitePath({ code }, Router.routes.quoteEdit, { id });

    case 'view':
      return Router.sitePath({ code }, Router.routes.quoteView, { id });

    case 'feedback':
      return Router.sitePath({ code }, Router.routes.quoteFeedback, { id });

    default:
      throw new UnreachableCaseError(action);
  }
}
