import {
  Box,
  type BoxProps,
  Link,
  Text,
  type TextProps,
  Tooltip,
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link as RouterLink } from 'react-router-dom';
import { useNavigationContext } from './context';
import type { NavigationItem } from './types';

type ItemProps = NavigationItem & {
  containerProps?: BoxProps;
  textProps?: TextProps;
};

export function Item({
  label,
  path,
  icon,
  containerProps = {},
  textProps = {},
}: ItemProps) {
  const { currentPath, isOpen } = useNavigationContext();

  const isActive = currentPath === path;

  return (
    <Link
      key={path.replace('/', '')}
      as={RouterLink}
      to={path}
      width="100%"
      height="50px"
      alignItems="center"
      display="flex"
      textDecoration="none !important"
      title={label}
      justifyContent={isOpen ? 'flex-start' : 'center'}
      color="mw.white"
      opacity={isActive ? 1 : 0.72}
      _hover={{
        opacity: 1,
      }}
      margin={0}
    >
      <Box
        display={isOpen ? 'flex' : 'inline-block'}
        fontSize={16}
        alignItems="center"
        {...containerProps}
      >
        <Tooltip label={label} placement="right" isDisabled={isOpen}>
          <FontAwesomeIcon icon={icon} width={36} />
        </Tooltip>

        <Text
          display="inline-block"
          opacity={isOpen ? 1 : 0}
          maxWidth={isOpen ? 'unset' : 0}
          maxHeight={isOpen ? 'unset' : 0}
          wordBreak="break-word"
          overflow="hidden"
          marginLeft={isOpen ? 3 : 0}
          fontSize=".875rem"
          fontWeight="semibold"
          fontFamily="navigationItem"
          {...textProps}
        >
          {label}
        </Text>
      </Box>
    </Link>
  );
}
