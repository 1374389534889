import type {
  QuoteWithBaseLinesDTO,
  QuoteWithFullLinesDTO,
} from '@prisma-types';
import type { Merge } from 'type-fest';
import type { WithPagination } from './pagination';
import type { QuoteLineFull } from './quote-line';
import { QuoteStatus } from '@prisma/client';

export const QuoteFeedbackReasons = {
  COST_VARIANCES: 'COST_VARIANCES',
  AGGRESSIVE_PRICING: 'AGGRESSIVE_PRICING',
  INDUSTRY_MARKET_SEGMENTATION: 'INDUSTRY_MARKET_SEGMENTATION',
  OTHER: 'OTHER',
};

export const ImmutableQuoteStates = ['SENT', 'WON', 'LOSS', 'DELETED'];

export const immutableQuoteStatus = [
  QuoteStatus.SENT,
  QuoteStatus.WON,
  QuoteStatus.LOSS,
  QuoteStatus.DELETED,
];

export type QuoteFull = Merge<
  QuoteWithFullLinesDTO,
  { lineItems: QuoteLineFull[] }
>;

export type QuoteWithBaseLines = QuoteWithBaseLinesDTO;

export type PaginatedQuoteHistory = WithPagination<QuoteWithBaseLines>;
