import { Box } from '@chakra-ui/react';
import type React from 'react';
import type { ReactNode } from 'react';

type PageWrapperProps = {
  children?: ReactNode;
};

export function PageWrapper({ children }: PageWrapperProps) {
  return (
    <Box width="100%" height="100vh" overflow="auto">
      {children}
    </Box>
  );
}
