import {
  RequirementCalculationParams,
  RequirementCalculationResults,
  calculateRequirementCosts,
} from "./requirement";

/**
 * Define this enum here to avoid any hard dependencies in this package.
 * Based on the SCRAP_YIELD_TYPE in Visual and its possible values.
 */
export enum ScrapYieldType {
  /**
   *  SCRAP = the value of the SCRAP_YIELD_PERCENT indicates the
   *  percentage of the input quantity that is discarded as scrap
   *  to get the output quantity
   */
  SCRAP = "SCRAP",

  /**
   * "YIELD" = the value of the SCRAP_YIELD_PERCENT indicates the
   * percentage of the input quantity that yields
   * the output quantity
   */
  YIELD = "YIELD",
}

/**
 * Define this enum here to avoid any hard dependencies in this package.
 * Based on the RUN_TYPE in Visual and its possible values
 * The values demonstrate different run rates of the operation
 * and are self-explanatory.
 */
export enum RunRateType {
  DAYS_PER_LOAD = "DAYS/LOAD",
  HRS_PER_PC = "HRS/PC",
  PCS_PER_MIN = "PCS/MIN",
  HRS_PER_LOAD = "HRS/LOAD",
  PCS_PER_HR = "PCS/HR",
  PCS_PER_DAY = "PCS/DAY",
  MIN_PER_PC = "MIN/PC",
  DAYS_PER_PC = "DAYS/PC",
  LOADS_PER_HR = "LOADS/HR",
  LOADS_PER_DAY = "LOADS/DAY",
}

export type OperationCalculationParams = {
  /**
   * The percentage of scrap or yield for this operation.
   * In Visual, this corresponds to the SCRAP_YIELD_PCT field in the OPERATION table.
   */
  scrapYieldPercent: number;

  /**
   * Indicates whether scrapYieldPercent corresponds to scrap or yield.
   * In Visual, this corresponds to the SCRAP_YIELD_TYPE field in the OPERATION table.
   *
   * See the ScrapYieldType enum in this file for more details.
   */
  scrapYieldType: ScrapYieldType;

  /**
   * The constant amount of scrap for this operation.
   * In Visual, this corresponds to the FIXED_SCRAP_UNITS field in the OPERATION table.
   */
  scrapFixedUnits: number;
  requirementParams: RequirementCalculationParams[];
  runRate: number;
  runRateType: RunRateType;
  loadSizeQuantity: number | null;
  setupHours: number;
  setupCostPerHour: number;
  runCostPerHour: number;
  runCostPerUnit: number;
  burdenCostPerHourSetup: number;
  burdenCostPerHourRun: number;
  burdenPerUnitRun: number;
  burdenPercentSetup: number;
  burdenPercentRun: number;
  serviceBaseCharge: number;
  serviceMinCharge: number;
  sequenceNumber: number;
  isServiceOperation: boolean;
};

export type OperationCalculationResults = {
  startQuantity: number;
  endQuantity: number;
  materialCost: number;
  laborCost: number;
  burdenCost: number;
  serviceCost: number;
  // sequenceNumber: number;
  requirementResults: RequirementCalculationResults[];
};

export function calculateOperationCosts(
  params: OperationCalculationParams,
  endQuantity: number,
): OperationCalculationResults {
  // first derive the start quantity of the operation from scrap values
  const startQuantity = calculateOperationStartQty(params, endQuantity);

  // get the requirement calculations which will provide material costs
  const requirementResults: RequirementCalculationResults[] = [];
  for (const req of params.requirementParams) {
    requirementResults.push(calculateRequirementCosts(req, startQuantity));
  }

  // compute the hours that the operation will run
  //  required for run cost and burden
  const runHours = calculateRunHours(params, endQuantity);

  // setup cost and run cost should be computed separately
  //  because it matters for computing burden
  const setupCost = calculateSetupCost(params);
  const runCost = calculateRunCost(params, runHours, endQuantity);

  // sum the requirement materials to get material cost
  const materialCost = requirementResults.reduce(function (total, req) {
    return total + req.materialCost;
  }, 0);

  const burdenCost = calculateBurdenCost(
    params,
    runHours,
    endQuantity,
    setupCost,
    runCost,
  );

  // labor and service are both based on "run cost" and mutually exclusive
  let laborCost = 0;
  let serviceCost = 0;
  if (params.isServiceOperation) {
    serviceCost = calculateServiceCost(params, setupCost, runCost);
  } else {
    laborCost = setupCost + runCost;
  }

  const results: OperationCalculationResults = {
    startQuantity,
    endQuantity,
    materialCost,
    burdenCost,
    laborCost,
    serviceCost,
    requirementResults,
  };

  return results;
}

export function calculateOperationStartQty(
  {
    scrapYieldPercent,
    scrapYieldType,
    scrapFixedUnits,
  }: Pick<
    OperationCalculationParams,
    "scrapYieldPercent" | "scrapYieldType" | "scrapFixedUnits"
  >,
  endQuantity: number,
): number {
  // get the % yield of the operation
  let percentYield = 1;

  if (scrapYieldPercent && scrapYieldType === ScrapYieldType.SCRAP) {
    percentYield = 1 - scrapYieldPercent / 100;
  } else if (scrapYieldPercent && scrapYieldType === ScrapYieldType.YIELD) {
    percentYield = scrapYieldPercent / 100;
  }

  // divide by the percent yield and add fixed scrap to get the input amount
  const startQuantity = endQuantity / percentYield + scrapFixedUnits;

  return startQuantity;
}

/**
 * The visual "run_hrs" field in the DB seems to be calculated and rounded,
 * and using it straight up seems to introduce marginal errors in the calculations.
 * Thus, calculate the run hours based on the quantity and provided run rate data.
 */
export function calculateRunHours(
  {
    runRate,
    runRateType,
    loadSizeQuantity,
  }: Pick<
    OperationCalculationParams,
    "runRate" | "runRateType" | "loadSizeQuantity"
  >,
  endQuantity: number,
): number {
  if (!endQuantity || !runRate) {
    return 0;
  }

  // If `estLoadSizeQty` is 0 or null, use 1 as the divisor for the number of
  //  loads calculation. Avoids a divide by 0 blowing this up to Infinity.
  const numberOfLoadsDivisor = loadSizeQuantity || 1;
  const numberOfLoads = Math.ceil(endQuantity / numberOfLoadsDivisor);

  switch (runRateType) {
    case RunRateType.PCS_PER_HR:
      return runRate ? endQuantity / runRate : 0;
    case RunRateType.HRS_PER_PC:
      return runRate * endQuantity;
    case RunRateType.DAYS_PER_PC:
      return runRate * 24 * endQuantity;
    case RunRateType.HRS_PER_LOAD:
      return runRate * numberOfLoads;
    case RunRateType.DAYS_PER_LOAD:
      return runRate * 24 * numberOfLoads;
    case RunRateType.PCS_PER_MIN:
      return runRate ? endQuantity / runRate / 60 : 0;
    case RunRateType.PCS_PER_DAY:
      return runRate ? (endQuantity / runRate) * 24 : 0;
    case RunRateType.LOADS_PER_HR:
      return runRate ? numberOfLoads / runRate : 0;
    case RunRateType.LOADS_PER_DAY:
      return runRate ? (numberOfLoads / runRate) * 24 : 0;
    default:
      return 0;
  }
}

export function calculateBurdenCost(
  {
    burdenCostPerHourSetup,
    burdenCostPerHourRun,
    burdenPerUnitRun,
    setupHours,
    burdenPercentSetup,
    burdenPercentRun,
  }: Pick<
    OperationCalculationParams,
    | "burdenCostPerHourSetup"
    | "burdenCostPerHourRun"
    | "burdenPerUnitRun"
    | "setupHours"
    | "burdenPercentSetup"
    | "burdenPercentRun"
  >,
  runHours: number,
  endQuantity: number,
  setupCost: number,
  runCost: number,
): number {
  const burdenCost =
    burdenCostPerHourSetup * setupHours +
    burdenCostPerHourRun * runHours +
    endQuantity * burdenPerUnitRun +
    (burdenPercentSetup / 100) * setupCost +
    (burdenPercentRun / 100) * runCost;

  return burdenCost;
}

export function calculateSetupCost({
  setupCostPerHour,
  setupHours,
}: Pick<OperationCalculationParams, "setupCostPerHour" | "setupHours">) {
  return setupCostPerHour * setupHours;
}

export function calculateRunCost(
  {
    runCostPerHour,
    runCostPerUnit,
  }: Pick<OperationCalculationParams, "runCostPerHour" | "runCostPerUnit">,
  runHours: number,
  endQuantity: number,
): number {
  return runCostPerHour * runHours + endQuantity * runCostPerUnit;
}

export function calculateServiceCost(
  {
    serviceBaseCharge,
    serviceMinCharge,
  }: Pick<OperationCalculationParams, "serviceBaseCharge" | "serviceMinCharge">,
  setupCost: number,
  runCost: number,
): number {
  // service cost is based on setup and run cost
  const calculatedServiceCharge = setupCost + runCost + serviceBaseCharge;

  // if there is a minimum charge and we haven't broken the threshold, use minimum charge
  if (calculatedServiceCharge < serviceMinCharge) {
    return serviceMinCharge;
  }

  return calculatedServiceCharge;
}
