import type { PreferredServiceVendor } from '@lib/models/configurator/static-part-metadata/preferred-service-vendors';
import type { UnitOfMeasure } from '@lib/models/configurator/unit-of-measure';
import {
  calculatePreferredVendorPricePerUnit,
  getPreferredVendors,
} from '@lib/util';
import { isError } from '@sindresorhus/is';
import { useQueryClient } from '@tanstack/react-query';
import type { SelectedItem } from '@ui/features/configurator/components/inputs/BaseSingleChoiceInput';
import { PREREQUISITE_FIELD_IDS } from '@ui/features/configurator/data';
import {
  type ConfiguratorServiceOptionMeta,
  buildServiceFieldUpdates,
  buildServicePricePerUnitUpdates,
} from '@ui/features/configurator/data/field-definitions/service';
import {
  type ConfiguratorVendorOptionMeta,
  vendorFieldDefinition,
} from '@ui/features/configurator/data/field-definitions/vendor';
import {
  FIELD_IDS,
  STEP_KEYS,
} from '@ui/features/configurator/data/fixtures/cold-headed-fastener';
import { useConfiguratorStore } from '@ui/features/configurator/hooks/use-configurator-store';
import {
  findStaticOption,
  setFieldError,
  updateField,
} from '@ui/features/configurator/store';
import { toggleFieldEditable } from '@ui/features/configurator/store/actions/toggle-field-editable';
import {
  ensureBooleanField,
  ensureNumberField,
  ensureSingleChoiceField,
  getFieldByStepId,
  getNumberFieldValueFromEvent,
  getOnlyFieldByStepKey,
} from '@ui/features/configurator/store/helpers/field-helpers';
import type {
  ConfiguratorBooleanFieldType,
  ConfiguratorNumberFieldType,
  ConfiguratorServiceFieldType,
  ConfiguratorSingleChoiceFieldType,
  ConfiguratorStoreAction,
} from '@ui/features/configurator/types';
import { useCurrentSite } from '@ui/hooks/useCurrentSite';
import {
  useCallback,
  useEffect,
  useMemo,
  useState,
  type ChangeEvent,
} from 'react';
import { getUntrackedObject } from 'react-tracked';

type PreferredServiceVendorsHookAPI = {
  standardServicePricingField: ConfiguratorBooleanFieldType;
  vendorField: ConfiguratorSingleChoiceFieldType<ConfiguratorVendorOptionMeta>;
  unitOfMeasureField: ConfiguratorSingleChoiceFieldType;
  baseChargeField: ConfiguratorNumberFieldType;
  minimumChargeField: ConfiguratorNumberFieldType;
  pricePerUnitOfMeasureField: ConfiguratorNumberFieldType;
  isPending: boolean;
  serviceId: string | null;
  useStandardPricing: boolean;
  notice: string | null;
  onServiceChange: (
    selectedService: SelectedItem<ConfiguratorServiceOptionMeta> | null,
  ) => void;
  onUseStandardPricingChange: (useStandardPricing: boolean) => void;
  onVendorChange: (
    vendor: SelectedItem<ConfiguratorVendorOptionMeta> | null,
  ) => void;
  onUnitOfMeasureChange: (uom: SelectedItem | null) => void;
  onPricePerUnitOfMeasureChange: (e: ChangeEvent<HTMLInputElement>) => void;
};

export function usePreferredServiceVendors(
  serviceField: ConfiguratorServiceFieldType,
): PreferredServiceVendorsHookAPI {
  const currentSite = useCurrentSite();
  const queryClient = useQueryClient();
  const { fields: trackedFields, blueprint, dispatch } = useConfiguratorStore();
  const [isPending, setIsPending] = useState(false);
  const [notice, setNotice] = useState<string | null>(null);
  const [preferredVendor, setPreferredVendor] =
    useState<PreferredServiceVendor | null>(null);

  /**
   * Using the tracked fields causes an issue with reusable operations:
   * proxy must report the same value for the non-writable, non-configurable property
   *
   * This is probably a sign to dump react-tracked, but I will do that in another PR
   * TODO: configurator - MW-701 - Remove react tracked
   */
  const fields = getUntrackedObject(trackedFields);

  if (!fields) {
    throw new Error('Tracked object not found');
  }

  const materialQuantityPerField = useMemo(
    () =>
      ensureNumberField(
        getOnlyFieldByStepKey(
          STEP_KEYS.material,
          PREREQUISITE_FIELD_IDS.material_quantity_per,
          fields,
          blueprint,
        ),
      ),
    [fields, blueprint],
  );

  const washerQuantityPerField = useMemo(
    () =>
      ensureNumberField(
        getOnlyFieldByStepKey(
          STEP_KEYS.sems,
          FIELD_IDS.washer_quantity_per,
          fields,
          blueprint,
        ),
      ),
    [fields, blueprint],
  );

  const totalMaterialQuantityPer =
    (washerQuantityPerField?.value ?? 0) +
    (materialQuantityPerField?.value ?? 0);

  const standardServicePricingField = useMemo(
    () =>
      ensureBooleanField(
        getFieldByStepId(
          serviceField.stepId,
          FIELD_IDS.standard_service_pricing,
          fields,
        ),
      ),
    [serviceField.stepId, fields],
  );

  const vendorField = useMemo(
    () =>
      ensureSingleChoiceField<ConfiguratorVendorOptionMeta>(
        getFieldByStepId(serviceField.stepId, FIELD_IDS.vendor, fields),
      ),
    [serviceField.stepId, fields],
  );

  const unitOfMeasureField = useMemo(
    () =>
      ensureSingleChoiceField(
        getFieldByStepId(
          serviceField.stepId,
          FIELD_IDS.unit_of_measure,
          fields,
        ),
      ),
    [serviceField.stepId, fields],
  );

  const baseChargeField = useMemo(
    () =>
      ensureNumberField(
        getFieldByStepId(serviceField.stepId, FIELD_IDS.base_charge, fields),
      ),
    [serviceField.stepId, fields],
  );

  const minimumChargeField = useMemo(
    () =>
      ensureNumberField(
        getFieldByStepId(serviceField.stepId, FIELD_IDS.minimum_charge, fields),
      ),
    [serviceField.stepId, fields],
  );

  const pricePerUnitField = useMemo(
    () =>
      ensureNumberField(
        getFieldByStepId(serviceField.stepId, FIELD_IDS.price_per_unit, fields),
      ),
    [serviceField.stepId, fields],
  );

  const pricePerUnitOfMeasureField = useMemo(
    () =>
      ensureNumberField(
        getFieldByStepId(
          serviceField.stepId,
          FIELD_IDS.price_per_unit_of_measure,
          fields,
        ),
      ),
    [serviceField.stepId, fields],
  );

  const fetchAndUpdatePreferredVendorAndCosts = useCallback(
    async (
      useStandardPricing: boolean,
      selectedService: SelectedItem<ConfiguratorServiceOptionMeta> | null,
    ) => {
      let actions: ConfiguratorStoreAction[] = [];
      // keep the existing value by default (may be overidden if we fetch a new vendor)
      let vendorOption = vendorField.value;
      let preferredVendor = null;

      /**
       * If "Use Standard Pricing" is selected, get the preferred vendors for
       * the selected service and:
       *
       * 1. Get the Preferred Vendor information for the selected Service
       * 2. Fetch the Vendor option for the first Preferred Vendor ID
       * 3. Update the current `vendor` field to the fetched Vendor option
       * 4. Set the service Base Charge, Minimum Charge, and Price Per Unit
       *    based on the PreferredServiceVendor data.
       *
       * If "Use Standard Pricing" is not selected, ensure the cost fields are
       * made editable again and don't display a notice.
       */
      if (!useStandardPricing) {
        setPreferredVendor(null);
        setNotice(null);
      } else {
        try {
          setIsPending(true);

          // 1. get preferred vendors for selected service
          const preferredVendors = await getPreferredVendors(
            currentSite.code,
            selectedService?.value,
          );

          if (preferredVendors.length > 0) {
            preferredVendor = preferredVendors[0];
            const vendorId = preferredVendor.vendorIds[0];

            /**
             * If Preferred Vendors are returned and the configured with no Vendor
             * IDs, we consider that an error attributed to misconfiguration.
             */
            if (!vendorId) {
              throw new Error(
                `Preferred Vendors definition for ${selectedService?.value} has no vendor IDs`,
              );
            }

            /**
             * As long as there are Preferred Vendors, set the "Use Standard
             * Pricing" checkbox to checked and editable.
             */
            actions.push(
              updateField(standardServicePricingField.fieldKey, true),
              toggleFieldEditable(standardServicePricingField.fieldKey, true),
            );

            // 2. fetch the vendor option for the first Preferred Vendor ID
            vendorOption = await queryClient.fetchQuery({
              queryKey: [
                'configurator',
                'dataSource',
                'site',
                currentSite.code,
                'vendor',
                vendorId,
              ],
              queryFn: () =>
                vendorFieldDefinition.fetchOne(currentSite.code, vendorId),
            });

            setPreferredVendor(preferredVendor);
            setNotice(
              'Standard charges have been pre-filled. You may still need to get a quote from the Vendor.',
            );
          } else {
            /**
             * If there are no Preferred Vendors for this Service, don't even
             * allow a user to click "Use Standard Pricing",
             */
            actions.push(
              updateField(standardServicePricingField.fieldKey, false),
              toggleFieldEditable(standardServicePricingField.fieldKey, false),
            );

            setPreferredVendor(null);
            setNotice(
              selectedService
                ? 'There are no preferred vendors for this service, contact a vendor for a quote.'
                : null,
            );
          }
        } catch (e: unknown) {
          console.error(e);

          dispatch(
            setFieldError(
              vendorField.fieldKey,
              `Failed to get preferred vendors for ${selectedService?.value}`,
            ),
          );

          setIsPending(false);

          return;
        }
      }

      /**
       * 3/4. Update the `vendor` and service cost fields, enabling or disabling
       * them depending on whether "Use Standard Pricing" is selected and the
       * available cost fields for the Preferred Vendor.
       */
      actions = actions.concat(
        buildServiceFieldUpdates(
          fields,
          useStandardPricing,
          vendorField,
          unitOfMeasureField,
          pricePerUnitOfMeasureField,
          vendorOption,
          preferredVendor,
          materialQuantityPerField.value ?? 0,
        ),
      );

      dispatch(actions);

      setIsPending(false);
    },
    [
      dispatch,
      fields,
      vendorField,
      unitOfMeasureField,
      pricePerUnitOfMeasureField,
      currentSite.code,
      standardServicePricingField.fieldKey,
      materialQuantityPerField.value,
      queryClient,
    ],
  );

  /**
   * When the selected Service changes, assume "Use Standard Pricing" is true in
   * order to perform the Preferred Vendors fetch/check code.
   */
  const handleServiceChange = useCallback(
    async (item: SelectedItem<ConfiguratorServiceOptionMeta> | null) => {
      dispatch(updateField(serviceField.fieldKey, item));
      fetchAndUpdatePreferredVendorAndCosts(true, item);
    },
    [dispatch, fetchAndUpdatePreferredVendorAndCosts, serviceField.fieldKey],
  );

  const handleUseStandardPricingChange = useCallback(
    (useStandardPricing: boolean) => {
      dispatch(
        updateField(standardServicePricingField.fieldKey, useStandardPricing),
      );

      fetchAndUpdatePreferredVendorAndCosts(
        useStandardPricing,
        serviceField.value,
      );
    },
    [
      fetchAndUpdatePreferredVendorAndCosts,
      standardServicePricingField,
      serviceField.value,
      dispatch,
    ],
  );

  /**
   * @NOTE(shawk): When a Service has a list of Preferred Vendors, the
   * `minCost`, `baseCost` and `pricePerUnit` doesn't change from one Vendor
   * to the next for a Service's Preferred Vendors, so there's no need
   * here to update cost fields.
   */
  const handleVendorChange = useCallback(
    (item: SelectedItem<ConfiguratorVendorOptionMeta> | null) => {
      dispatch(updateField(vendorField.fieldKey, item));
    },
    [vendorField.fieldKey, dispatch],
  );

  /**
   * When Unit of Measure changes, set the Price Per Unit based on the
   * calculated value using the new Unit of Measure value.
   */
  const handleUnitOfMeasureChange = useCallback(
    (item: SelectedItem | null) => {
      const selectedOption = findStaticOption(
        unitOfMeasureField,
        fields,
        item?.value,
      );

      const actions = [
        updateField(
          unitOfMeasureField.fieldKey,
          findStaticOption(unitOfMeasureField, fields, item?.value),
        ),
      ];

      if (pricePerUnitOfMeasureField.value && selectedOption) {
        actions.push(
          updateField(
            pricePerUnitField.fieldKey,
            calculatePreferredVendorPricePerUnit(
              totalMaterialQuantityPer,
              selectedOption.value as UnitOfMeasure,
              pricePerUnitOfMeasureField.value,
            ),
          ),
        );
      }

      dispatch(actions);
    },
    [
      unitOfMeasureField,
      fields,
      pricePerUnitOfMeasureField.value,
      dispatch,
      pricePerUnitField.fieldKey,
      totalMaterialQuantityPer,
    ],
  );

  /**
   * When Price Per Unit of Measure changes, set the Price Per Unit based on the
   * calculation using the current selected Unit of Measure.
   */
  const handlePricePerUnitOfMeasureChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const actions = [];

      try {
        const value = getNumberFieldValueFromEvent(e);

        actions.push(updateField(pricePerUnitOfMeasureField.fieldKey, value));

        if (value !== null && unitOfMeasureField.value) {
          actions.push(
            updateField(
              pricePerUnitField.fieldKey,
              calculatePreferredVendorPricePerUnit(
                totalMaterialQuantityPer,
                unitOfMeasureField.value.value as UnitOfMeasure,
                value,
              ),
            ),
          );
        }
      } catch (e) {
        /**
         * @NOTE(shawk): Just in case, but unlikely given this field is rendered
         * as an `input[type="number"]`, so it's value should always be valid.
         */
        actions.push(
          setFieldError(
            pricePerUnitOfMeasureField.fieldKey,
            isError(e) ? e.message : 'Unexpected error',
          ),
        );
      }

      dispatch(actions);
    },
    [
      dispatch,
      pricePerUnitField.fieldKey,
      pricePerUnitOfMeasureField.fieldKey,
      unitOfMeasureField.value,
      totalMaterialQuantityPer,
    ],
  );

  /**
   * This effect makes sure that the Outside Service's Price Per Unit reflects
   * the latest calculated value based on the material quantity per field.
   *
   * This only runs if a Service has been selected and the Material Quantity Per
   * field is not empty to avoid running validations on empty fields.
   *
   * In this case specifically, we want to ensure we don't change the user's
   * selected Unit of Measure. (They may have changed it from the Vendor's
   * suggested Unit of Measure).
   */
  useEffect(() => {
    if (serviceField.value && materialQuantityPerField.value !== null) {
      dispatch(
        buildServicePricePerUnitUpdates(
          fields,
          vendorField,
          unitOfMeasureField,
          pricePerUnitOfMeasureField,
          preferredVendor,
          totalMaterialQuantityPer,
          { updateUnitOfMeasure: false },
        ),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- only needs to run when material quantity per change
  }, [totalMaterialQuantityPer]);

  const api = useMemo(() => {
    return {
      standardServicePricingField,
      vendorField,
      unitOfMeasureField,
      baseChargeField,
      minimumChargeField,
      pricePerUnitOfMeasureField,
      isPending,
      serviceId: serviceField.value?.value ?? null,
      useStandardPricing: standardServicePricingField.value || false,
      notice,
      onServiceChange: handleServiceChange,
      onUseStandardPricingChange: handleUseStandardPricingChange,
      onVendorChange: handleVendorChange,
      onUnitOfMeasureChange: handleUnitOfMeasureChange,
      onPricePerUnitOfMeasureChange: handlePricePerUnitOfMeasureChange,
    } satisfies PreferredServiceVendorsHookAPI;
  }, [
    standardServicePricingField,
    vendorField,
    unitOfMeasureField,
    baseChargeField,
    minimumChargeField,
    pricePerUnitOfMeasureField,
    isPending,
    serviceField.value,
    notice,
    handleServiceChange,
    handleUseStandardPricingChange,
    handleVendorChange,
    handleUnitOfMeasureChange,
    handlePricePerUnitOfMeasureChange,
  ]);

  return api;
}
