/* eslint-disable @typescript-eslint/no-explicit-any */
// @TODO(NT): I don't even know...
// @SEE: https://mwcomponents.atlassian.net/browse/MW-870
import type { IPublicClientApplication } from '@azure/msal-browser';
import axios, { type AxiosInstance } from 'axios';
import { loginRequest } from '../config/authConfig';
import { EnvConfig } from '../config/env';

interface APIConstructs {
  baseUri?: string;
  /** used for MS AD token handling */
  msalInstance?: IPublicClientApplication;
  defaultHeaders?: Record<string, any>;
}

export class API {
  baseUri = EnvConfig.getKey('API_BASEURL');
  axiosInstance: AxiosInstance;

  constructor({ baseUri, msalInstance, defaultHeaders }: APIConstructs) {
    this.axiosInstance = axios.create({
      baseURL: baseUri ?? this.baseUri ?? '',
      headers: defaultHeaders || {},
    });
    // add msal bearer token to header on each request
    if (msalInstance) {
      this.axiosInstance.interceptors.request.use(async (config) => {
        const account = msalInstance.getActiveAccount();
        if (!account) {
          throw Error(
            'No active msal account! Verify a user has been signed in and setActiveAccount has been called.',
          );
        }
        const tokenResponse = await msalInstance.acquireTokenSilent({
          ...loginRequest,
          account,
        });
        // TODO token error handling, retry login?
        config.headers.Authorization = `Bearer ${tokenResponse.accessToken}`;
        return config;
      });
    }
  }

  get = async <T = any>(path: string, params?: Record<string, any>) => {
    const resp = await this.axiosInstance.get<T>(path, { params });
    return resp.data;
  };

  post = async <T = any>(path: string, data: any) => {
    const resp = await this.axiosInstance.post<T>(path, data);
    return resp.data;
  };

  put = async <T = any>(path: string, data: any) => {
    const resp = await this.axiosInstance.put<T>(path, data);
    return resp.data;
  };

  delete = async <T = any>(path: string) => {
    const resp = await this.axiosInstance.delete<T>(path);
    return resp.data;
  };

  postFile = async <T = any>(path: string, data: FormData) => {
    const resp = await this.axiosInstance.post<T>(path, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return resp.data;
  };

  getArrayBuffer = async <T = any>(path: string) => {
    const resp = await this.axiosInstance.get<T>(path, {
      responseType: 'arraybuffer',
    });
    return resp.data;
  };

  sitePath = (siteCode: string, path: string) => {
    return `/sites/${siteCode.toLowerCase()}${path}`;
  };
}
