type EnvNames = 'default' | 'integration' | 'staging' | 'production';

type EnvConfigType = {
  API_BASEURL: string;
  SENTRY_DSN: string;
  SENTRY_DEBUG: boolean;
  AD_CLIENT_ID: string;
  AD_AUTHORITY_URL: string;
  AD_SCOPES: string;
  IDLE_TIMOUT_SECONDS: string;
  IDLE_TIMEOUT_PROMPT_BEFORE_SECONDS: string;
  MW_COMPONENTS_URI: string;
};

export class EnvConfig {
  static configs = {
    default: {
      API_BASEURL: import.meta.env.VITE_API_BASEURL ?? 'http://localhost:8000',
      SENTRY_DSN: import.meta.env.VITE_SENTRY_DSN ?? '',
      SENTRY_DEBUG: import.meta.env.VITE_SENTRY_DEBUG === 'true',
      AD_CLIENT_ID: '5d86d05b-652e-4f4f-9281-41ac53347f7d',
      AD_AUTHORITY_URL:
        'https://login.microsoftonline.com/c70cfdb0-d54a-48c7-94b5-3ca6beb09191',
      AD_SCOPES: '5d86d05b-652e-4f4f-9281-41ac53347f7d/.default',
      IDLE_TIMOUT_SECONDS: '28800',
      IDLE_TIMEOUT_PROMPT_BEFORE_SECONDS: '120',
      MW_COMPONENTS_URI: 'https://integration.rap-mwi.com',
    },
    integration: {
      API_BASEURL: 'https://api.integration.snap.mwcomponents.com',
      SENTRY_DSN: import.meta.env.VITE_SENTRY_DSN ?? '',
      SENTRY_DEBUG: false,
      AD_CLIENT_ID: '5d86d05b-652e-4f4f-9281-41ac53347f7d',
      AD_AUTHORITY_URL:
        'https://login.microsoftonline.com/c70cfdb0-d54a-48c7-94b5-3ca6beb09191',
      AD_SCOPES: '5d86d05b-652e-4f4f-9281-41ac53347f7d/.default',
      IDLE_TIMOUT_SECONDS: '28800',
      IDLE_TIMEOUT_PROMPT_BEFORE_SECONDS: '120',
      MW_COMPONENTS_URI: 'https://integration.rap-mwi.com',
    },
    staging: {
      API_BASEURL: 'https://api.staging.snap.mwcomponents.com',
      SENTRY_DSN: import.meta.env.VITE_SENTRY_DSN ?? '',
      SENTRY_DEBUG: false,
      AD_CLIENT_ID: '5d86d05b-652e-4f4f-9281-41ac53347f7d',
      AD_AUTHORITY_URL:
        'https://login.microsoftonline.com/c70cfdb0-d54a-48c7-94b5-3ca6beb09191',
      AD_SCOPES: '5d86d05b-652e-4f4f-9281-41ac53347f7d/.default',
      IDLE_TIMOUT_SECONDS: '28800',
      IDLE_TIMEOUT_PROMPT_BEFORE_SECONDS: '120',
      MW_COMPONENTS_URI: 'https://staging.rap-mwi.com',
    },
    production: {
      API_BASEURL: 'https://api.snap.mwcomponents.com',
      SENTRY_DSN: import.meta.env.VITE_SENTRY_DSN ?? '',
      SENTRY_DEBUG: false,
      AD_CLIENT_ID: '5d86d05b-652e-4f4f-9281-41ac53347f7d',
      AD_AUTHORITY_URL:
        'https://login.microsoftonline.com/c70cfdb0-d54a-48c7-94b5-3ca6beb09191',
      AD_SCOPES: '5d86d05b-652e-4f4f-9281-41ac53347f7d/.default',
      IDLE_TIMOUT_SECONDS: '28800',
      IDLE_TIMEOUT_PROMPT_BEFORE_SECONDS: '120',
      MW_COMPONENTS_URI: 'https://www.mwcomponents.com',
    },
  } as const satisfies Record<EnvNames, EnvConfigType>;

  static getEnv(hostName = window.location.hostname): EnvNames {
    const envMap: Record<string, EnvNames> = {
      'integration.snap.mwcomponents.com': 'integration',
      'staging.snap.mwcomponents.com': 'staging',
      'snap.mwcomponents.com': 'production',
      localhost: 'default',
      default: 'default',
    };

    return envMap[hostName ?? 'default'] ?? envMap.default;
  }

  public static getKey(name: keyof EnvConfigType) {
    const env = EnvConfig.getEnv(window.location.hostname);
    return EnvConfig.configs[env][name];
  }
}
