import type { UserWithSettingsResponse } from '@lib/responses/user';
import { useAuth } from '@ui/state/auth';

export type CurrentUser = UserWithSettingsResponse;

export function useCurrentUser(): CurrentUser {
  const { user } = useAuth();

  if (!user) {
    throw new Error('Current user expected but missing!');
  }

  return user;
}
