import { PermActions } from '@lib/permissions';
import { ResourceEnum } from '@prisma/client';

import type { RoutesConfig } from './types';

export const routesConfig = {
  root: {
    label: 'Home',
    path: '/',
  },
  quoteQueue: {
    label: 'Quote Queue',
    path: '/sites/:siteCode/quotes',
    //  permissions:  { [ResourceEnum.QUOTE]: [PermActions.READ] },
  },
  quoteNew: {
    label: 'Create Quote',
    path: '/sites/:siteCode/quotes/new',
    //  permissions:  { [ResourceEnum.QUOTE]: [PermActions.WRITE] },
  },
  quoteEdit: {
    label: 'Edit Quote',
    path: '/sites/:siteCode/quotes/:id',
    //  permissions:  { [ResourceEnum.QUOTE]: [PermActions.READ] },
  },
  quoteView: {
    label: 'View Quote',
    path: '/sites/:siteCode/quotes/:id/view',
    //  permissions:  { [ResourceEnum.QUOTE]: [PermActions.READ] },
  },
  quoteFeedback: {
    label: 'Quote Feedback',
    path: '/sites/:siteCode/quotes/:id/feedback',
  },
  admin: {
    label: 'Admin',
    path: '/admin',
    permissions: {
      [ResourceEnum.ADMIN]: [PermActions.READ, PermActions.UPDATE],
      [ResourceEnum.BETA]: [PermActions.READ],
    },
  },
  adminUsers: {
    label: 'Users',
    path: '/admin/users',
    permissions: {
      [ResourceEnum.ADMIN]: [PermActions.READ],
      [ResourceEnum.ADMIN_USERS]: [PermActions.READ, PermActions.UPDATE],
      [ResourceEnum.BETA]: [PermActions.READ],
    },
  },
  adminSites: {
    label: 'Sites',
    path: '/admin/sites',
    permissions: {
      [ResourceEnum.ADMIN]: [PermActions.READ],
      [ResourceEnum.ADMIN_SITE]: [PermActions.READ],
      [ResourceEnum.BETA]: [PermActions.READ],
    },
  },
  adminSiteConfig: {
    label: 'Site Config',
    path: '/admin/sites/:siteCode/config',
    permissions: {
      [ResourceEnum.ADMIN]: [PermActions.READ],
      [ResourceEnum.ADMIN_SITE]: [PermActions.READ, PermActions.UPDATE],
      [ResourceEnum.BETA]: [PermActions.READ],
    },
  },
  adminMetrics: {
    label: 'Metrics',
    path: '/admin/metrics',
    permissions: {
      [ResourceEnum.ADMIN]: [PermActions.READ],
      [ResourceEnum.ADMIN_METRICS]: [PermActions.READ],
      [ResourceEnum.BETA]: [PermActions.READ],
    },
  },
  userProfile: {
    label: 'User Profile',
    path: '/current-user',
  },
  calculationTestPage: {
    label: 'Calc Test Page',
    path: '/calc-test',
  },
} as const satisfies RoutesConfig;
