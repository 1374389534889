export const unitOfMeasureOptions = ['ea', 'cwt', 'lbs', 'm'] as const;

export type UnitOfMeasure = (typeof unitOfMeasureOptions)[number];

export const unitOfMeasureLabelMap = {
  cwt: 'CWT',
  ea: 'EA',
  lbs: 'LBS',
  m: 'M',
} as const satisfies Record<UnitOfMeasure, string>;

export const unitOfMeasureDescriptionMap = {
  cwt: 'per 100 lbs',
  ea: 'per unit',
  lbs: 'per pound',
  m: 'per 1000 units',
} as const satisfies Record<UnitOfMeasure, string>;
