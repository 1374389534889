import React from 'react';
import type { SiteWithConfigResponse } from '@lib/responses/site';

export type CurrentSite = SiteWithConfigResponse;

export const CurrentSiteContext = React.createContext<CurrentSite | null>(null);

export function useCurrentSite(): CurrentSite {
  const currentSite = React.useContext(CurrentSiteContext);

  if (!currentSite) {
    throw new Error('Current site expected but missing!');
  }

  return currentSite;
}
