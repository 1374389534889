import { type EventMessage, EventType } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import type { UserWithSettingsResponse } from '@lib/responses/user';
import { userService } from '@ui/services';
import { useEffect, useMemo, useState } from 'react';
import { AuthContext } from './context';

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const [user, setUser] = useState<UserWithSettingsResponse | null>(null);
  const [isError, setIsError] = useState(false);
  const [isLoggedOut, setIsLoggedOut] = useState(false);
  const { instance: msalInstance } = useMsal();

  // we will listen to account removed events so we can handle if the user is
  // logged out from another tab/window
  useEffect(() => {
    msalInstance.enableAccountStorageEvents();
    msalInstance.addEventCallback((event: EventMessage) => {
      if (event.eventType === EventType.ACCOUNT_REMOVED) {
        handleOutsideLogout();
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getProfile = async () => {
    // api call to get user data
    try {
      const userResponse = await userService.getUser();

      setIsLoggedOut(false);
      setIsError(false);
      setUser(userResponse);
    } catch (error) {
      console.error('Get user error: ', error);
      setIsError(true);
    }
  };

  const logout = () => {
    const currentAccount = msalInstance.getActiveAccount();
    // state will be reset by redirect
    msalInstance.logoutRedirect({ account: currentAccount });
  };

  const handleOutsideLogout = () => {
    setIsLoggedOut(true);
    setUser(null);
    setIsError(false);
  };

  const value = useMemo(
    () => ({
      user,
      getProfile,
      logout,
      isError,
      isLoggedOut,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [user, isError, isLoggedOut],
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
