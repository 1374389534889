import {
  calculateOperationCosts,
  OperationCalculationParams,
  OperationCalculationResults,
} from "./operation";

export type WorkOrderCalculationParams = {
  quantity: number;
  operationParams: OperationCalculationParams[];
};

export type WorkOrderCalculationResults = {
  materialCost: number;
  burdenCost: number;
  laborCost: number;
  serviceCost: number;
  operationResults: OperationCalculationResults[];
};

export function reverseSortOperations(
  operationParams: OperationCalculationParams[],
) {
  return operationParams.toSorted(
    (a, b) => b.sequenceNumber - a.sequenceNumber,
  );
}

export function calculateWorkOrderCosts(
  params: WorkOrderCalculationParams,
): WorkOrderCalculationResults {
  // sort the operations by sequence number in reverse order
  //  to work out the operation start quantities with scrap
  const operationParams = reverseSortOperations(params.operationParams);

  // start with the final operation end quantity = the work order quantity
  let endQuantity = params.quantity;

  const operationResults: OperationCalculationResults[] = [];

  for (const op of operationParams) {
    // calculate operation values
    const opCalculationResult = calculateOperationCosts(op, endQuantity);
    operationResults.push(opCalculationResult);

    // set the end quantity (of the prior operation)
    //  to the start quantity (of the current operation)
    endQuantity = opCalculationResult.startQuantity;
  }

  // re-sort by sequence number in order
  operationResults.reverse();

  // compute total costs from all operations
  const materialCost = operationResults.reduce(function (total, op) {
    return total + op.materialCost;
  }, 0);

  const laborCost = operationResults.reduce(function (total, op) {
    return total + op.laborCost;
  }, 0);

  const burdenCost = operationResults.reduce(function (total, op) {
    return total + op.burdenCost;
  }, 0);

  const serviceCost = operationResults.reduce(function (total, op) {
    return total + op.serviceCost;
  }, 0);

  return { materialCost, burdenCost, laborCost, serviceCost, operationResults };
}
