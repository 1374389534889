import { round } from '@lib/calculations';
import type { PreferredServiceVendor } from '@lib/models/configurator/static-part-metadata/preferred-service-vendors';
import type { UnitOfMeasure } from '@lib/models/configurator/unit-of-measure';
import { UnreachableCaseError } from '@lib/validation';

export async function getPreferredVendors(
  siteCode: string,
  serviceId: string | undefined,
): Promise<PreferredServiceVendor[]> {
  if (!serviceId) {
    return [];
  }

  const preferredVendors: PreferredServiceVendor[] = await import(
    `../../models/configurator/static-part-metadata/data/json/preferred-service-vendors/${siteCode}.json`
  ).then((module) => module.default);

  return preferredVendors.filter((v) => v.serviceId === serviceId);
}

export function calculatePreferredVendorPricePerUnit(
  totalMaterialWeightPerEach: number,
  uom: UnitOfMeasure,
  costPerUom: number,
) {
  let cost = 0;

  switch (uom) {
    /**
     * The vendor cost is per 100 lbs, so we divide the per piece weight by 100
     * and multiply that by the vendor's cost per unit of measure.
     */
    case 'cwt':
      cost = (totalMaterialWeightPerEach / 100) * costPerUom;
      break;

    /**
     * The vendor cost is per piece, so we just use it directly.
     */
    case 'ea':
      cost = costPerUom;
      break;

    /**
     * The vendor cost is in lbs, so we multiply the per piece weight by the
     * vendor's cost per pound.
     */
    case 'lbs':
      cost = totalMaterialWeightPerEach * costPerUom;
      break;

    /**
     * The vendor cost is per 1000 pieces so we divide the vendor's price
     * by 1000.
     */
    case 'm':
      cost = costPerUom / 1000;
      break;

    default:
      throw new UnreachableCaseError(uom);
  }

  return round(cost, {
    min: 4,
    small: 4,
    max: 4,
  });
}
