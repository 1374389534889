import type { PaginationParams } from '@lib/models';
import { configuratorVendorSchema } from '@lib/validation';
import type { ConfiguratorDynamicFieldDefinition } from '@ui/features/configurator/types';
import { configuratorService } from '@ui/services';

export type ConfiguratorVendorOptionMeta = {
  id: string;
  name: string | null;
};

export type ConfiguratorVendorParams = {
  serviceId?: string;
};

export const vendorFieldDefinition: ConfiguratorDynamicFieldDefinition<
  ConfiguratorVendorOptionMeta,
  ConfiguratorVendorParams
> = {
  endpoints: {
    list: '/configurator/vendors',
    get: '/configurator/vendor',
  },

  mapOption: (data) => {
    const vendor = configuratorVendorSchema.parse(data);

    return {
      label: vendor.name ?? vendor.id,
      value: vendor.id,
      meta: {
        id: vendor.id,
        name: vendor.name,
      },
    };
  },

  mapOptions: (resources) => {
    return resources.map(vendorFieldDefinition.mapOption);
  },

  fetchOne: (siteCode, id, params) => {
    return configuratorService.getSingleOption({
      siteCode,
      fieldDefinition: vendorFieldDefinition,
      id,
      params,
    });
  },

  search: ({
    siteCode,
    query,
    pagination,
    params,
  }: {
    siteCode: string;
    query: string;
    pagination: PaginationParams;
    params?: ConfiguratorVendorParams;
  }) => {
    return configuratorService.getFieldOptions({
      siteCode,
      fieldDefinition: vendorFieldDefinition,
      query,
      pagination,
      params,
    });
  },
};
