import { Badge, Box, Text } from '@chakra-ui/react';
import { Show } from '@ui/components/Show';
import { FIELD_IDS } from '@ui/features/configurator/data/fixtures/cold-headed-fastener';
import { useConfiguratorStore } from '@ui/features/configurator/hooks';
import {
  createFieldKey,
  setFieldError,
  updateField,
} from '@ui/features/configurator/store';
import type { ConfiguratorMaterialFieldType } from '@ui/features/configurator/types';
import { memo, useCallback } from 'react';
import {
  ConfiguratorBaseSingleChoiceInput,
  type SelectedItem,
} from './BaseSingleChoiceInput';
import type { ConfiguratorInputProps } from './types';

const MATERIAL_INPUT_COST_FIELDS = {
  material: FIELD_IDS.material_cost,
} as const;

function MaterialInputComponent({
  field,
  ...widths
}: ConfiguratorInputProps<ConfiguratorMaterialFieldType>) {
  const { dispatch } = useConfiguratorStore();

  if (field.inputType !== 'material') {
    throw new Error(`Invalid input type "${field.inputType}"`);
  }

  if (!field.inputSource) {
    throw new Error(
      `material inputs must have an associated input_source, but "${field.fieldKey}" had none`,
    );
  }

  const handleSelectedItemChange = useCallback(
    async (item: SelectedItem | null) => {
      dispatch(updateField(field.fieldKey, item));

      // update related material cost field, if found
      const inputSourceKey = field.fieldKey.split('.')[1];
      const costFieldId =
        MATERIAL_INPUT_COST_FIELDS[
          inputSourceKey as keyof typeof MATERIAL_INPUT_COST_FIELDS
        ];

      if (!costFieldId) {
        console.error(
          `MaterialInput: could not find related cost field for '${inputSourceKey}'`,
        );
        return;
      }

      if (
        item &&
        item.meta?.unitMaterialCost !== undefined &&
        item.meta?.unitMaterialCost !== null
      ) {
        dispatch(
          updateField(
            createFieldKey(field.stepId, costFieldId),
            item.meta.unitMaterialCost as number,
          ),
        );
      } else {
        dispatch(
          setFieldError(
            field.fieldKey,
            'The Material did not have a unit cost',
          ),
        );
      }
    },

    [dispatch, field],
  );

  return (
    <Box
      maxWidth={widths.maxWidth ?? '200px'}
      minWidth={widths.minWidth ?? '200px'}
    >
      <ConfiguratorBaseSingleChoiceInput
        field={field}
        onItemSelected={handleSelectedItemChange}
        {...widths}
      />

      <Show when={field.value?.meta?.description}>
        <Text mt={2} fontSize="small">
          {field.value?.meta?.description}
        </Text>
      </Show>

      <Show when={field.value?.meta?.stockUm}>
        <Box display="flex" alignItems="center" gap={1} mt={2}>
          <Text fontSize="small">Material Unit:</Text>
          <Badge>{field.value?.meta?.stockUm}</Badge>
        </Box>
      </Show>
    </Box>
  );
}

/**
 * Material input for Configurator forms.
 *
 * Requires ConfiguratorStoreProvider in a parent component.
 */
export const ConfiguratorMaterialInput = memo(MaterialInputComponent);
