import { Center, Text } from '@chakra-ui/react';
import { PageHeader } from '@ui/components/layout/PageHeader';
import { PageWrapper } from '@ui/components/layout/PageWrapper';

export function ErrorUnauthorized() {
  return (
    <PageWrapper>
      <PageHeader title="Error: Unauthorized" />
      <Center>
        <Text color="red">You are unauthorized to view this page</Text>
      </Center>
    </PageWrapper>
  );
}

export default ErrorUnauthorized;
