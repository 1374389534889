import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import * as Sentry from '@sentry/react';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { App } from './App';
import { GlobalError } from './components/GlobalError';
import { msalInstance } from './config/authConfig';
import { queryClient } from './config/query-client';
import MWTheme from './config/theme';
import { ErrorMonitoring } from './services/error-monitoring';

const theme = extendTheme(MWTheme);

ErrorMonitoring.init();

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <ChakraProvider theme={theme}>
        <BrowserRouter>
          <Sentry.ErrorBoundary
            fallback={({ resetError }) => <GlobalError onReset={resetError} />}
          >
            <App msalInstance={msalInstance} />
          </Sentry.ErrorBoundary>
        </BrowserRouter>
      </ChakraProvider>
      <ReactQueryDevtools />
    </QueryClientProvider>
  </React.StrictMode>,
);
