import { useQuery } from '@tanstack/react-query';
import { quoteService } from '@ui/services';

export const quoteQuery = (id: string) => {
  return {
    queryKey: ['quote', id],
    queryFn: () => quoteService.getQuote(id),
  };
};

export function useGetQuote(id: string) {
  return useQuery({
    ...quoteQuery(id),
  });
}
