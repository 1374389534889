import {
  Alert as ChakraAlert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  AlertProps as ChakraAlertProps,
} from '@chakra-ui/react';

export const AlertStatuses = [
  'info',
  'warning',
  'success',
  'error',
  'loading',
] as const satisfies NonNullable<ChakraAlertProps['status'][]>;

export type AlertStatus = (typeof AlertStatuses)[number];

export type AlertProps = {
  title: string;
  description?: string;
  status: AlertStatus;
  children?: React.ReactNode;
};

export function Alert({ title, description, status, children }: AlertProps) {
  return (
    <ChakraAlert status={status}>
      <AlertIcon />
      <AlertTitle>{title}</AlertTitle>
      {description && <AlertDescription>{description}</AlertDescription>}
      {children}
    </ChakraAlert>
  );
}
