import React from 'react';
import { useLocation } from 'react-router-dom';
import { Root } from './Root';
import { NavigationContext } from './context';

type MenuProps = {
  children?: React.ReactNode;
};

export function Menu({ children }: MenuProps) {
  const { pathname } = useLocation();
  const [isOpen, setIsOpen] = React.useState<boolean>(true);

  const windowWidthValueRef = React.useRef(window.innerWidth);

  React.useEffect(() => {
    const onResize = () => {
      windowWidthValueRef.current = window.innerWidth;

      if (window.innerWidth >= 1000) {
        setIsOpen(true);
      } else {
        setIsOpen(false);
      }
    };

    window.addEventListener('resize', onResize);

    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, []);

  const value = React.useMemo<NavigationContext>(() => {
    return {
      currentPath: pathname,
      isOpen,
      setIsOpen: (newVal: boolean) => {
        setIsOpen(newVal);
      },
    };
  }, [pathname, isOpen]);

  return (
    <NavigationContext.Provider value={value}>
      <Root>{children}</Root>
    </NavigationContext.Provider>
  );
}
