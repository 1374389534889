import { Divider } from './Divider';
import { Menu } from './Menu';
import { Item } from './Item';
import { Section } from './Section';
import { SiteSelector } from './SiteSelector';

export const Navigation = {
  Divider,
  Item,
  Menu,
  Section,
  SiteSelector,
};
