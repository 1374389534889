import { Flex } from '@chakra-ui/react';
import { FullScreenLoader } from '@ui/components/FullScreenLoader';
import { Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import { Navigation } from '@ui/components/layout/Navigation';
import { Router } from '@ui/config/routes';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { ModalLayout } from '@ui/components/layout/ModalLayout';

export function UserLayout() {
  return (
    <>
      <ModalLayout />

      <Flex direction="row">
        <Navigation.Menu>
          <Navigation.Item
            label="Back to SNAP"
            path={Router.path(Router.routes.root)}
            icon={faArrowLeft}
          />
        </Navigation.Menu>

        {/* @NOTE(shawk): ensure <NavigationBar /> stays visible
            while descendent lazy-loaded routes are loading */}
        <Suspense fallback={<FullScreenLoader />}>
          <Outlet />
        </Suspense>
      </Flex>
    </>
  );
}
