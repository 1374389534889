import React from 'react';

export type NavigationContext = {
  isOpen: boolean;
  setIsOpen: (newVal: boolean) => void;
  currentPath: string;
};

export const NavigationContext = React.createContext<NavigationContext | null>(
  null,
);

export function useNavigationContext() {
  const ctx = React.useContext(NavigationContext);

  if (ctx === null) {
    throw new Error(
      'useNavigationContext must be used within a Navigation.Menu',
    );
  }

  return ctx;
}
