import {
  Heading,
  Input,
  Flex,
  Text,
  Select,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
} from '@chakra-ui/react';
import { Button } from '@ui/components/Button';
import {
  WorkOrderCalculationParams,
  OperationCalculationParams,
  RequirementCalculationParams,
  OperationCalculationResults,
  RequirementCalculationResults,
  RunRateType,
  ScrapYieldType,
  calculateWorkOrderCosts,
} from '@calculations';
import React, { useState } from 'react';

function RequirementBlock(
  compositeParams: RequirementCalculationParams & {
    index: number;
    updateRequirement: (
      params: RequirementCalculationParams,
      index: number,
    ) => void;
  },
) {
  const { updateRequirement, index, ...params } = compositeParams;

  function handleChange(e: React.ChangeEvent<HTMLInputElement>, field: string) {
    const input = e.target.value;
    if (!input || input.match(/^\d*(\.\d*)?$/)) {
      updateRequirement({ ...params, [field]: Number(input) || 0 }, index);
    }
  }

  return (
    <>
      <Flex direction={'column'} gap={'1'}>
        <Heading size={'sm'}>Requirement {index}</Heading>
        <Flex gap="1" align="center" justify="space-between" direction="row">
          <Text width="40" align={'right'}>
            Quantity Per
          </Text>
          <Input
            width="40"
            onChange={function (e) {
              handleChange(e, 'qtyPer');
            }}
          />
        </Flex>

        <Flex gap="1" align="center" justify="space-between" direction="row">
          <Text width="40" align={'right'}>
            Fixed Quantity
          </Text>
          <Input
            width="40"
            onChange={function (e) {
              handleChange(e, 'fixedQty');
            }}
          />
        </Flex>

        <Flex gap="1" align="center" justify="space-between" direction="row">
          <Text width="40" align={'right'}>
            Unit Material Cost
          </Text>
          <Input
            width="40"
            onChange={function (e) {
              handleChange(e, 'unitMaterialCost');
            }}
          />
        </Flex>
      </Flex>
    </>
  );
}

function OperationBlock(
  compositeParams: OperationCalculationParams & {
    updateOperation: (params: OperationCalculationParams) => void;
  },
) {
  const { updateOperation, ...params } = compositeParams;

  function handleChange(e: React.ChangeEvent<HTMLInputElement>, field: string) {
    const input = e.target.value;
    if (!input || input.match(/^\d*(\.\d*)?$/)) {
      updateOperation({ ...params, [field]: Number(input) || 0 });
    }
  }

  function addRequirement() {
    const newRequirement: RequirementCalculationParams = {
      qtyPer: 0,
      fixedQty: 0,
      unitMaterialCost: 0,
    };

    const requirementParams = [...params.requirementParams];
    requirementParams.push(newRequirement);
    updateOperation({ ...params, requirementParams });
  }

  function removeRequirement() {
    const requirementParams = [...params.requirementParams];
    requirementParams.pop();
    updateOperation({ ...params, requirementParams });
  }

  function updateRequirement(
    reqParams: RequirementCalculationParams,
    index: number,
  ) {
    const requirementParams = [...params.requirementParams];
    requirementParams[index] = reqParams;
    updateOperation({ ...params, requirementParams });
  }

  return (
    <>
      <Flex direction={'column'} gap="1">
        <Heading size={'md'}>Operation {params.sequenceNumber}</Heading>

        <Flex gap="1" align="center" justify="space-between" direction="row">
          <Text width="40" align={'right'}>
            Setup Hours
          </Text>
          <Input
            width="40"
            onChange={function (e) {
              handleChange(e, 'setupHours');
            }}
          />
        </Flex>

        <Flex gap="1" align="center" justify="space-between" direction="row">
          <Text width="40" align={'right'}>
            Run Rate
          </Text>
          <Input
            width="40"
            onChange={function (e) {
              handleChange(e, 'runRate');
            }}
          />
        </Flex>

        <Flex gap="1" align="center" justify="space-between" direction="row">
          <Text width="40" align={'right'}>
            Run Rate Type
          </Text>
          <Select
            width="40"
            onChange={function (e) {
              updateOperation({
                ...params,
                runRateType: e.target.value as RunRateType,
              });
            }}
          >
            {Object.values(RunRateType).map((key: string) => {
              return <option value={key}>{key}</option>;
            })}
          </Select>
        </Flex>

        <Flex gap="1" align="center" justify="space-between" direction="row">
          <Text width="40" align={'right'}>
            Load Size
          </Text>
          <Input
            width="40"
            onChange={function (e) {
              handleChange(e, 'loadSizeQuantity');
            }}
          />
        </Flex>

        <Flex gap="1" align="center" justify="space-between" direction="row">
          <Text width="40" align={'right'}>
            Scrap/Yield %
          </Text>
          <Input
            width="40"
            onChange={function (e) {
              handleChange(e, 'scrapYieldPercent');
            }}
          />
        </Flex>

        <Flex gap="1" align="center" justify="space-between" direction="row">
          <Text width="40" align={'right'}>
            Scrap/Yield Type
          </Text>
          <Select width="40">
            {Object.keys(ScrapYieldType).map((key) => {
              return <option value={key}>{key}</option>;
            })}
            ;
          </Select>
        </Flex>

        <Flex gap="1" align="center" justify="space-between" direction="row">
          <Text width="40" align={'right'}>
            Scrap Fixed Units
          </Text>
          <Input
            width="40"
            onChange={function (e) {
              handleChange(e, 'scrapFixedUnits');
            }}
          />
        </Flex>

        <Flex gap="1" align="center" justify="space-between" direction="row">
          <Text width="40" align={'right'}>
            Setup Cost / Hour
          </Text>
          <Input
            width="40"
            onChange={function (e) {
              handleChange(e, 'setupCostPerHour');
            }}
          />
        </Flex>

        <Flex gap="1" align="center" justify="space-between" direction="row">
          <Text width="40" align={'right'}>
            Run Cost / Hour
          </Text>
          <Input
            width="40"
            onChange={function (e) {
              handleChange(e, 'runCostPerHour');
            }}
          />
        </Flex>

        <Flex gap="1" align="center" justify="space-between" direction="row">
          <Text width="40" align={'right'}>
            Run Cost / Unit
          </Text>
          <Input
            width="40"
            onChange={function (e) {
              handleChange(e, 'runCostPerUnit');
            }}
          />
        </Flex>

        <Flex gap="1" align="center" justify="space-between" direction="row">
          <Text width="40" align={'right'}>
            Burden / Hr Setup
          </Text>
          <Input
            width="40"
            onChange={function (e) {
              handleChange(e, 'burdenCostPerHourSetup');
            }}
          />
        </Flex>

        <Flex gap="1" align="center" justify="space-between" direction="row">
          <Text width="40" align={'right'}>
            Burden / Hr Run
          </Text>
          <Input
            width="40"
            onChange={function (e) {
              handleChange(e, 'burdenCostPerHourRun');
            }}
          />
        </Flex>

        <Flex gap="1" align="center" justify="space-between" direction="row">
          <Text width="40" align={'right'}>
            Burden / Unit
          </Text>
          <Input
            width="40"
            onChange={function (e) {
              handleChange(e, 'burdenPerUnitRun');
            }}
          />
        </Flex>

        <Flex gap="1" align="center" justify="space-between" direction="row">
          <Text width="40" align={'right'}>
            Burden % - Setup
          </Text>
          <Input
            width="40"
            onChange={function (e) {
              handleChange(e, 'burdenPercentSetup');
            }}
          />
        </Flex>

        <Flex gap="1" align="center" justify="space-between" direction="row">
          <Text width="40" align={'right'}>
            Burden % - Run
          </Text>
          <Input
            width="40"
            onChange={function (e) {
              handleChange(e, 'burdenPercentRun');
            }}
          />
        </Flex>

        <Flex gap="1" align="center" justify="space-between" direction="row">
          <Text width="40" align={'right'}>
            Service Base Charge
          </Text>
          <Input
            width="40"
            onChange={function (e) {
              handleChange(e, 'serviceBaseCharge');
            }}
          />
        </Flex>

        <Flex gap="1" align="center" justify="space-between" direction="row">
          <Text width="40" align={'right'}>
            Service Min. Charge
          </Text>
          <Input
            width="40"
            onChange={function (e) {
              handleChange(e, 'serviceMinCharge');
            }}
          />
        </Flex>

        <Flex gap="1" align="center" justify="space-between" direction="row">
          <Text width="40" align={'right'}>
            Service Op
          </Text>
          <Select
            width="40"
            defaultValue={params.isServiceOperation ? 'true' : 'false'}
            onChange={function (e) {
              updateOperation({
                ...params,
                isServiceOperation: Boolean(e.target.value),
              });
            }}
          >
            <option value={'true'}>Yes</option>;
            <option value={'false'}>No</option>;
          </Select>
        </Flex>

        {params.requirementParams.map(
          (requirement: RequirementCalculationParams, index: number) => {
            return (
              <RequirementBlock
                {...requirement}
                key={index}
                index={index}
                updateRequirement={updateRequirement}
              ></RequirementBlock>
            );
          },
        )}

        <Button onClick={addRequirement}>Add Requirement</Button>
        {params.requirementParams.length > 0 ? (
          <Button onClick={removeRequirement}>Remove Requirement</Button>
        ) : null}
      </Flex>
    </>
  );
}

export function CalculationTestPage() {
  const [calculationParams, setCalculationParams] =
    useState<WorkOrderCalculationParams>({ quantity: 0, operationParams: [] });

  function updateOperation(params: OperationCalculationParams) {
    const newCalcParams = { ...calculationParams };
    newCalcParams.operationParams[params.sequenceNumber / 10 - 1] = params;

    setCalculationParams(newCalcParams);
  }

  function addOperation() {
    const newCalcParams = { ...calculationParams };

    const lastSequenceNumber =
      calculationParams.operationParams[
        calculationParams.operationParams.length - 1
      ]?.sequenceNumber || 0;

    const operationParams: OperationCalculationParams = {
      sequenceNumber: lastSequenceNumber + 10,
      scrapYieldPercent: 0,
      scrapFixedUnits: 0,
      scrapYieldType: ScrapYieldType.SCRAP,
      requirementParams: [],
      runRate: 0,
      runRateType: RunRateType.PCS_PER_HR,
      loadSizeQuantity: null,
      setupCostPerHour: 0,
      serviceBaseCharge: 0,
      serviceMinCharge: 0,
      setupHours: 0,
      burdenCostPerHourRun: 0,
      burdenCostPerHourSetup: 0,
      burdenPerUnitRun: 0,
      runCostPerHour: 0,
      runCostPerUnit: 0,
      isServiceOperation: false,
      burdenPercentRun: 0,
      burdenPercentSetup: 0,
    };

    newCalcParams.operationParams.push(operationParams);
    setCalculationParams(newCalcParams);
  }

  const { materialCost, laborCost, burdenCost, serviceCost, operationResults } =
    calculateWorkOrderCosts(calculationParams);

  const totalCost = materialCost + laborCost + burdenCost + serviceCost;

  return (
    <>
      <Flex direction={'column'}>
        <Heading>Calculation Test Page</Heading>
        <Flex direction="row">
          <Input
            placeholder="Line Quantity"
            width={'80'}
            onChange={function (e) {
              setCalculationParams({
                ...calculationParams,
                quantity: Number(e.target.value) || 0,
              });
            }}
          />
        </Flex>
        <Flex direction="row">
          <Button onClick={addOperation}>Add Operation</Button>
        </Flex>
        <Flex direction="row">
          {calculationParams.operationParams.map(
            (operation: OperationCalculationParams) => {
              return (
                <OperationBlock
                  {...operation}
                  key={operation.sequenceNumber}
                  updateOperation={updateOperation}
                ></OperationBlock>
              );
            },
          )}
        </Flex>

        <Heading size={'lg'}>Results</Heading>
        <TableContainer>
          <Table variant={'striped'}>
            <Thead>
              <Th></Th>
              <Th>Quantity</Th>
              <Th>Total Cost</Th>
              <Th>Unit Cost</Th>
              <Th>Material</Th>
              <Th>Labor</Th>
              <Th>Burden</Th>
              <Th>Service</Th>
            </Thead>
            <Tbody>
              <Tr>
                <Td>Total</Td>
                <Td>{calculationParams.quantity.toFixed(0)}</Td>
                <Td>{totalCost.toFixed(2)}</Td>
                <Td>
                  {(totalCost / calculationParams.quantity || 0).toFixed(2)}
                </Td>
                <Td>{materialCost.toFixed(2)}</Td>
                <Td>{laborCost.toFixed(2)}</Td>
                <Td>{burdenCost.toFixed(2)}</Td>
                <Td>{serviceCost.toFixed(2)}</Td>
              </Tr>
              {operationResults.map(
                (operation: OperationCalculationResults, index: number) => {
                  const totalOpCost =
                    operation.laborCost +
                    operation.materialCost +
                    operation.burdenCost +
                    operation.serviceCost;
                  return (
                    <>
                      <Tr>
                        <Td>Operation {(index + 1) * 10}</Td>
                        <Td>{operation.endQuantity.toFixed(0)}</Td>
                        <Td>{totalOpCost.toFixed(2)}</Td>
                        <Td>
                          {(totalOpCost / operation.endQuantity || 0).toFixed(
                            2,
                          )}
                        </Td>
                        <Td>{operation.materialCost.toFixed(2)}</Td>
                        <Td>{operation.laborCost.toFixed(2)}</Td>
                        <Td>{operation.burdenCost.toFixed(2)}</Td>
                        <Td>{operation.serviceCost.toFixed(2)}</Td>
                      </Tr>
                      {operation.requirementResults.map(
                        (
                          requirement: RequirementCalculationResults,
                          index: number,
                        ) => {
                          return (
                            <Tr>
                              <Td>Requirement {index}</Td>
                              <Td>{requirement.quantity.toFixed(0)}</Td>
                              <Td>{requirement.materialCost.toFixed(2)}</Td>
                              <Td>
                                {(
                                  requirement.materialCost /
                                    requirement.quantity || 0
                                ).toFixed(2)}
                              </Td>
                              <Td>{requirement.materialCost.toFixed(2)}</Td>
                              <Td>{'-'}</Td>
                              <Td>{'-'}</Td>
                              <Td>{'-'}</Td>
                            </Tr>
                          );
                        },
                      )}
                    </>
                  );
                },
              )}
            </Tbody>
          </Table>
        </TableContainer>
        {/* <Flex direction={'row'} gap={4}>
            <Text>Material Cost</Text>
            <Text align={'right'}>{materialCost}</Text>
          </Flex>
          <Flex direction={'row'} gap={4}>
            <Text>Burden Cost</Text>
            <Text align={'right'}>{burdenCost}</Text>
          </Flex>
          <Flex direction={'row'} gap={4}>
            <Text>Labor Cost</Text>
            <Text align={'right'}>{laborCost}</Text>
          </Flex>
          <Flex direction={'row'} gap={4}>
            <Text>Service Cost</Text>*/}
      </Flex>
    </>
  );
}
